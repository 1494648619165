<template>
	<div style="display: flex;flex-direction: column; flex:1;">
		<div class="ads-class"
			style="background-image: linear-gradient(rgb(108, 155, 251), rgb(143, 133, 238)); border-radius: 10px;">
			<div style="min-height: 250px; margin-top: 2px;">
				<p style="margin: 0px; color: rgb(255, 255, 255); font-size: 10px; text-align: center;">Advertisement
				</p>
			</div>
		</div>
		<loading v-if="uniqueCategories.length == 0"></loading>
		<div v-if="uniqueCategories.length > 0 && !isMobileLayout" class="grid-all">
			<div class="recome-game">{{categoryTitle}}</div>
			<div class="grid" style="margin-top: 10px;">
				<game-item v-for="game in categoryGames" :game="game" :key="game.handle" :showTitle="false"></game-item>
			</div>
		</div>
		<div v-if="uniqueCategories.length > 0 && isMobileLayout" >
			<div style="padding: 10px 10px 0px;">
				<div class="top-main-left-top" style="margin: 0px;">{{categoryTitle}}</div>
			</div>
			<div class="mo-grid" style="margin: 0px; padding: 10px; border-radius: 0px;">
				<game-item v-for="game in categoryGames" :game="game" :key="game.handle" :showTitle="false" style="height: 100%; width: 100%; box-shadow: none; border-radius: 4px; display: flex; border: none; padding: 0px;"></game-item>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapState
	} from 'vuex'
	import GameItem from '@/components/common/game-item'
	import Loading from '@/components/common/loading'
	export default {
		inject: ['app'],
		components: {
			Loading,
			GameItem
		},
		computed: {
			...mapGetters({
				uniqueCategories: 'games/uniqueCategories',
			}),
			...mapState({
				games: state => state.games.games
			}),
			categoryTitle() {
				const item = this.uniqueCategories.find(v => v.category_handle == this.$route.params.id)
				return item && item.category
			},
			categoryGames() {
				return this.games.filter(v => v.category_handle == this.$route.params.id)
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		},
		watch: {
			uniqueCategories(newVal, oldVal) {
				if (newVal.length > 0) {
					const existed = newVal.find(v => v.category_handle == this.$route.params.id)
					if (existed === undefined) {
						this.$router.push('/')
					}
				}
			}
		}
	}
</script>

<style scoped>
	.game-itemss {
		display: inline-block;
		cursor: pointer;
		width: 100%;
		overflow: hidden;
		height: 87px;
		border-radius: 4px
	}

	.game-false {
		height: 130px
	}

	.gane-name {
		overflow: hidden;
		margin: .35714em;
		color: #4a4a4a;
		font-size: .875em;
		text-overflow: ellipsis;
		white-space: nowrap;
		text-align: center;
		font-family: Dosis
	}

	.game-item-div {
		height: 122px;
		width: 144px;
		-webkit-transition: .2s;
		transition: .2s;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		display: block;
		padding: .125em;
		border: 2px solid #fff;
		border-radius: 5px;
		background: #fff;
		text-decoration: none;
		-webkit-transition: box-shadow .45s cubic-bezier(.23, 1, .32, 1) 0ms;
		transition: box-shadow .45s cubic-bezier(.23, 1, .32, 1) 0ms;
		box-shadow: 0 1px 6px 0 rgba(50, 50, 50, .45);
		margin: 0
	}

	.game-item-icon {
		position: absolute;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover
	}

	.game {
		padding: 5px 10px 10px 10px;
		-webkit-transition: .2s;
		transition: .2s;
		text-align: center;
		color: #fff
	}

	.game-min {
		padding: 2px 10px 3px 10px
	}

	.game-bottom {
		font-weight: 500;
		font-size: 13px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 1px
	}

	.game-bottom-play {
		font-size: 11px;
		margin-top: 7px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-justify-content: space-around;
		justify-content: space-around
	}

	.game-bottom-favorites {
		cursor: pointer
	}

	@media (max-width: 540px) {
		.game-itemss {
			height: 106px;
			padding-bottom: 100%
		}

		.game {
			padding: 1px 10px 2px 10px
		}
	}

	.mo-top-main-dialog {
		position: fixed;
		z-index: 99;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #0000009c;
		touch-action: none
	}

	.mo-top-main-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		flex-direction: column;
		height: 80%;
		background: -webkit-gradient(linear, left top, left bottom, from(#8364da), to(#f4b7f9));
		background: -webkit-linear-gradient(#8364da, #f4b7f9);
		background: linear-gradient(#8364da, #f4b7f9)
	}

	.mo-top-main {
		padding: 10px;
		padding-top: 30px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-auto-rows: auto;
		grid-auto-flow: dense;
		grid-gap: 10px
	}

	.mo-top-main-item {
		padding: .3125rem 0;
		border-radius: .625rem;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .25);
		color: #936de4;
		font-size: 10px;
		background-color: #f2e0f8;
		cursor: pointer;
		width: 100%;
		text-align: center
	}

	.mo-top-span {
		padding: 0 .5rem;
		white-space: nowrap;
		overflow: hidden;
		font-weight: 700;
		display: block
	}

	.mo-top-bottom {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		padding: 10px;
		padding-bottom: 20px
	}

	.mo-top-close {
		width: 40px;
		height: 40px;
		padding: 10px;
		border-radius: 9999px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .25);
		background-color: #f2e0f8
	}

	.header-center-bottom {
		display: none;
		color: #000;
		position: absolute
	}

	.mo-fav-play {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-justify-content: space-around;
		justify-content: space-around;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		align-items: center
	}

	.mo-menu {
		background: #f2e0f8;
		border: 1px solid #936de4;
		padding: 2px;
		border-radius: 9999px
	}

	.right {
		font-size: 10px;
		text-align: center
	}

	.right p {
		margin: 0;
		line-height: 1.5
	}

	.footer {
		overflow: hidden;
		padding: 10px;
		color: #913bc6;
		font-size: 9px;
		text-align: center;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#ecb1f6), to(#8866db));
		background-image: -webkit-linear-gradient(#ecb1f6, #8866db);
		background-image: linear-gradient(#ecb1f6, #8866db);
		bottom: 0;
		width: 100%
	}

	.footer a {
		margin: 0 8px;
		line-height: 20px
	}

	.back-top {
		position: fixed;
		z-index: 4;
		bottom: 86px;
		right: 10px;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		box-shadow: 2px 0 5px rgba(0, 0, 0, .3);
		background: #c392eb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR+SURBVHgB7d1fqN9zHMfx1w9lhYSyCxQpqZVIFCVHUaJsIdLaLJpYRFj+RCaS0ZGsk5E1f+bfyAn5N6lzI25R7rhwYzduXO1i22evT99zsbO2nd+f75/P9/d+Purdp3XOWb/f5/0679/vfP+cIwEAAAAAgCgGy31CMqGvFgaDwXXH+4QThNAIQHAEIDgCEBwBCI4ABEcAgiMAwRGA4AhAcAQgOAIQHAEIjgAERwCCO2mIz9mipdcNpMV/Jx39eoJjffzw6wra/PhyXwcAAIJJKd3hWiHE48avTZWvXGcJcbjhG9JSP7nOE6afG70xHd0vrouF6eUGb0rH97vrcmH6uLGPpOH85bpGmB5u6OY0mn9dNwr950Y+ncbzv+s2ob/cwOfSZA641gn948a9mOpzv9AfbtjWVL/HhPK5Ua+l5jwrlMsN2paa97JQHjdme2rPNqEcbsiO1L4dQvfciPdTdz5xcXlZV7z5H6fufe06VWiPN/xE1+epHPl08kqhed7oFa4vU3l+dV0oNMcbfJrr21SuP1yXCPXzxp7p+jGV72/XVUJ9vKErXQupP/a6rlcPFH9rmDfyXC9fuK5Vf+Q3hPN+7GtUuKJ/hvUGXuDlU9cV6qd8G9q6wWDwoQpV7ARw8y9S9Z3f1+Zn+Rtsl5/LfSpUkQHwhq1S1fxLNR3e8nN6VAUqLgDeqNz0edcqTZfZVODp5KLeA3iD8rjf7Tpf02ur3xM8qUIUEwA3/2pVzT9H02/OIXhQBSgiAG7+jKp3+2crjp0OwT3qWOcBcPNvUNX8MxTPbofgTnWo0wC4+Tepan7k06nfuNY4CPvVgc5+CnDzV6v6US/6ufSbXXu8H6erA50EwE/2dlXNP1nI8h92yiFo/Q1w6wHwk7zLy2fiV9Qd6UrXD4tHQFvTahP85NZ7+Ug4lnzw63vv02VqSWsB8JO618t7wnLyCbAcglZuUW8lAKm6t+4dYVj5eMh3qYVb1BsPgJ/EQ17eFEZ1iitf/tboLeqNBmDxDNgbwrjycZp89fPdakhjAfCDfsLLrFCHd72fm9SARgLgB/uMF26irNec93WzalZ7APwgn/fygtCEV7y/W1SjWs8F+MG95OUpoWmzPnfwuGpQWwDc/Fe91PKgMJTtDsEDmlAtAXDzX/fysNC2DxyC9ZrAxAFw8+e8NPIOFUOZdwhu1ZgmCoCb/7aXjULX9rhWOwj7NKKxA+Dm7/SyQSjFz6pC8N8oXzRWANz8XV7WCqX5zXWLQ/DPsF8wcgDc/K4u3pxRv+TjIQdcB2uuYf7PvQ7Bn0M8xqH+bNwSXVzEuHhwaUY9470q/oBYX67K4RcxNaQvAeDysYYwAYJjAgTHBAiOCRAcAQiOl4DgmADBMQGCYwIExwQIjgkQHAEIjpeA4JgAwTEBgmMCBMcECI4JEBwTIDgmQHAEIDheAoJjAgTHBAiOCRAcEyA4JkBwBCA4XgKCYwIExwQIjgkQHBOgOUk9wAQIjgA0pxdTi5eA4JgAwY38ewI7kifAggAAAAAAADC2QzxH7I+G3TK/AAAAAElFTkSuQmCC) no-repeat 50%/20px
	}

	.dialog-main {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		z-index: 0
	}

	.dialog-body,
	.dialog-main {
		position: fixed;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		align-items: center;
		justify-items: center
	}

	.dialog-body {
		min-width: 300px;
		max-width: 430px;
		min-height: 168px;
		color: #00335e;
		background: #fff;
		border-radius: 6px;
		z-index: 99;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		padding: 10px;
		width: 80%
	}

	.msg-box-title {
		padding: 5px 0 5px 0;
		font-size: 16px;
		font-weight: lighter;
		color: #00335e;
		width: 100%;
		border-bottom: 1px solid #28abfa
	}

	.msg-box-container {
		font-family: Verdana, Geneva, Tahoma, sans-serif;
		color: #00335e;
		padding: 10px 0 0;
		line-height: 22px;
		font-size: 15px
	}

	.msg-box-image {
		margin: 0 5px 0 0;
		display: block;
		float: left;
		height: 75px;
		width: 75px
	}

	.ms-box-buttons {
		display: inline-block;
		width: 100%;
		text-align: right
	}

	.ms-bottom {
		cursor: pointer;
		margin: 2px;
		height: 35px;
		width: 90px;
		border: 1px solid #afafaf;
		background-color: #28abfa;
		color: #fff;
		border-radius: 6px;
		outline: none
	}

	.mo-grid {
		padding: 10px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(31%, auto));
		grid-auto-rows: auto;
		grid-auto-flow: dense;
		grid-gap: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		margin: 5px 5px 0 5px;
		border-radius: 10px 10px 0 0
	}

	.grid-all {
		background-image: -webkit-gradient(linear, left top, left bottom, from(#6c9bfb), to(#c47fef));
		background-image: -webkit-linear-gradient(#6c9bfb, #c47fef);
		background-image: linear-gradient(#6c9bfb, #c47fef);
		border-radius: 10px;
		padding: 0 10px 10px 10px;
		margin: 0 auto;
		width: 1417px
	}

	.grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(145px, 0fr));
		grid-auto-rows: auto;
		grid-auto-flow: dense;
		grid-gap: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		min-width: 1120px
	}

	.top-main {
		width: 1417px;
		margin: 10px auto;
		border-radius: 10px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex
	}

	.top-main-left {
		width: 760px;
		border-radius: 10px;
		background-color: #fff;
		height: 352px;
		padding: 0 22px 16px 22px
	}

	.top-main-left-top {
		margin: 0
	}

	.recome-game,
	.top-main-left-top {
		display: inline-block;
		padding: 0 20px;
		border-radius: 9999px;
		color: #fff;
		font-family: Dosis;
		font-weight: 600;
		font-size: 20px
	}

	.recome-game {
		line-height: 1.5;
		margin-top: 8px
	}

	.recome-game p {
		line-height: 1.5;
		font-family: Dosis;
		font-weight: 600;
		margin-top: 8px
	}

	.top-main-left-main {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(145px, 0fr));
		grid-auto-rows: auto;
		grid-auto-flow: dense;
		grid-gap: 10px
	}

	.top-main-right {
		width: 614px;
		border-radius: 10px;
		height: 352px;
		padding: 0 22px 16px 22px
	}

	.mo-ads1 {
		grid-column: 1/-1;
		grid-row: 4/6
	}

	.ads-class {
		width: 1417px;
		text-align: center;
		margin: 10px auto
	}

	.load-more {
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-moz-box-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-moz-box-pack: center;
		justify-content: center;
		border-radius: 10px;
		border: 2px solid #fff;
		padding: 8px;
		color: #fff;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#f4e3f7), to(#cd82df));
		background-image: -webkit-linear-gradient(#f4e3f7, #cd82df);
		background-image: linear-gradient(#f4e3f7, #cd82df);
		width: 300px;
		height: 50px;
		margin: 0 auto;
		font-size: 20px;
		font-weight: 700;
		cursor: pointer;
		-webkit-transition: all .6s ease;
		transition: all .6s ease
	}

	.load-more :hover {
		-webkit-transform: scale(1.2);
		transform: scale(1.2)
	}

	.bottom-category {
		width: 1417px
	}

	.load-more-class {
		width: 1417px;
		border-radius: 0 0 10px 10px;
		margin: 0 auto;
		height: 100px;
		padding-top: 10px
	}

	@media (max-width: 540px) {
		.load-more :hover {
			-webkit-transition: none;
			transition: none;
			-webkit-transform: none;
			transform: none
		}

		.load-more-class {
			width: auto;
			margin: 0 5px
		}
	}

	@media (max-width: 425px) {
		.bottom-category {
			width: 450px
		}
	}

	@media (max-width: 540px) {
		.bottom-category {
			grid-template-columns: repeat(2, auto);
			width: 450px
		}
	}

	@media (max-width: 450px) {
		.bottom-category {
			grid-template-columns: repeat(2, auto);
			width: 330px
		}
	}

	@media (max-width: 340px) {
		.bottom-category {
			grid-template-columns: repeat(2, auto);
			width: 260px
		}
	}

	.arrow-right {
		width: 13px;
		height: 5px;
		display: -webkit-box;
		display: -webkit-flex;
		display: -moz-box;
		display: flex
	}

	.arrow-right :after,
	.arrow-right :before {
		content: "";
		width: 5px;
		height: 5px;
		background-color: transparent;
		border-color: #000;
		border-style: solid;
		border-width: 2px 2px 0 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg)
	}
</style>